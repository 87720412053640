<template>
  <div class="welcome-model">
    <CModal :show.sync="isShowPopup" :closeOnBackdrop="false" :centered="true">
      <template #header>
        <button aria-label="Close" class="close" @click="closeModal">x</button>
      </template>
      <div class="text-center px-3 pb-4">
        <h3 class="mb-3">{{ title }}</h3>
        <h5>{{ message }}</h5>
      </div>
      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>
  </div>
</template>
<script>
export default {
  name: "WelcomeModel",
  props: {
    message: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    closeModal: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
.model-header{
    border: none !important;
}
// .modal {
//   display: block;
//   position: fixed;
//   z-index: 1200;
//   padding-top: 100px;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   overflow: auto;
//   background-color: rgb(0, 0, 0);
//   background-color: rgba(0, 0, 0, 0.9);
// }

// .modal-content {
//   margin: auto;
//   display: block;
//   width: 80%;
//   max-width: 700px;
// }

// .modal-content img {
//   margin: auto;
//   display: block;
//   width: 100%;
//   max-width: 700px;
// }

// .modal-content {
//   -webkit-animation-name: zoom;
//   -webkit-animation-duration: 0.6s;
//   animation-name: zoom;
//   animation-duration: 0.6s;
// }

// @-webkit-keyframes zoom {
//   from {
//     -webkit-transform: scale(0);
//   }
//   to {
//     -webkit-transform: scale(1);
//   }
// }

// @keyframes zoom {
//   from {
//     transform: scale(0);
//   }
//   to {
//     transform: scale(1);
//   }
// }

// .close {
//   position: absolute;
//   top: 28px;
//   right: 28px;
//   color: #f1f1f1;
//   font-size: 40px;
//   font-weight: bold;
//   transition: 0.3s;
// }

// .close:hover,
// .close:focus {
//   color: #bbb;
//   text-decoration: none;
//   cursor: pointer;
// }

// @media only screen and (max-width: 700px) {
//   .modal-content {
//     width: 100%;
//   }
// }
</style>
