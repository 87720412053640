import { render, staticRenderFns } from "./CourseTemplateView.vue?vue&type=template&id=e248f152"
import script from "./CourseTemplateView.vue?vue&type=script&lang=js"
export * from "./CourseTemplateView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports