<template>
  <CToaster v-if="getFixedToasters.show" position="bottom-right">
    <template v-if="getFixedToasters.show">
      <CToast
        :key="getFixedToasters.id"
        :class="`${getFixedToasters.class} d-flex`"
        :autohide="false"
        :show="true"
      >
        <div class="d-flex justify-content-between">
          <div>{{ getFixedToasters.message }}</div>
          <CSpinner class="position-absolute p-right" color="warning" grow size="sm" />
        </div>
      </CToast>
    </template>
  </CToaster>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getFixedToasters"]),
  },
};
</script>

<style scoped>
.p-right{
  right: 20px;
}
</style>
