<template>
  <div class="course-template">
    <div v-for="(temp, index) in templates" :key="index">
      <component :is="temp.component" :data="temp.data" />
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import CourseTemplateSchema from "@/schema/courseTemplateSchema";
import Template1 from "@/containers/CourseTemplate/Template-1.vue";
import Template2 from "@/containers/CourseTemplate/Template-2.vue";
import Template3 from "@/containers/CourseTemplate/Template-3.vue";
import Template4 from "@/containers/CourseTemplate/Template-4.vue";

Vue.component("Template1", Template1);
Vue.component("Template2", Template2);
Vue.component("Template3", Template3);
Vue.component("Template4", Template4);
export default {
  name: "CourseTemplate",
  props: {
    templateData: {
      type: Array,
      default: [],
    },
  },
  computed: {
    templates() {
      return this.templateData.map((v) => {
        const res = CourseTemplateSchema.find(
          (temp) => temp.template_id === v.template_id
        );
        const data = v.template_fields.reduce(
          (a, v) => ({ ...a, [v.field_name]: v.field_value }),
          {}
        );
        return {
          component: res?.component,
          data
        };
      });
    },
  },
};
</script>
